// @ts-nocheck
'use client';

import { motion } from 'motion/react';
import React, { useEffect,useState } from 'react';

export const SvgLinesComponent = () => {
  const [numLines, setNumLines] = useState(null); // Initialize to null
  const maxAnimatedLines = 3; // Up to 3 animated lines at the same time
  const visibleLineLength = 1000; // Visible length of the line in SVG units
  const tracingLineLength = 30; // Length of the tracing line in SVG units
  const lineLength = visibleLineLength + 2 * tracingLineLength; // Total length of the line path

  const [animatedLines, setAnimatedLines] = useState([]);

  // Custom hook to detect if we're on mobile
  const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(null);

    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      const handleChange = () => setIsMobile(mediaQuery.matches);

      // Set initial value
      setIsMobile(mediaQuery.matches);

      // Listen for changes
      mediaQuery.addEventListener('change', handleChange);

      // Cleanup
      return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    return isMobile;
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile === null) return; // Wait until we know if it's mobile or not
    setNumLines(isMobile ? 6 : 9); // Nice
  }, [isMobile]);

  useEffect(() => {
    if (numLines === null) return; // Don't run until numLines is set

    const generateAnimatedLines = () => {
      const lineIndices = Array.from({ length: numLines - 1 }, (_, i) => i + 1);
      const shuffledIndices = lineIndices.sort(() => 0.5 - Math.random());
      const selectedIndices = shuffledIndices.slice(0, maxAnimatedLines);

      return selectedIndices.map((lineIndex) => {
        const animationDelay = Math.random() * 20; // Delay between 0 and 20 seconds
        const animationDuration = 15 + Math.random() * 30; // Duration between 15 and 45 seconds
        const direction = Math.random() < 0.5 ? 'normal' : 'reverse'; // Random direction
        return { lineIndex, animationDelay, animationDuration, direction };
      });
    };

    setAnimatedLines(generateAnimatedLines());

    const interval = setInterval(() => {
      setAnimatedLines(generateAnimatedLines());
    }, 60000); // Update every 60 seconds

    return () => clearInterval(interval);
  }, [numLines]); // Depend on numLines so that it updates when numLines changes

  const linePath = `M1.5 ${-tracingLineLength} V${visibleLineLength + tracingLineLength
    }`;

  // Don't render until numLines is set to prevent flashing
  if (numLines === null) return null;

  return (
    <div className="pointer-events-none absolute -z-10 h-full w-full overflow-x-hidden">
      {Array.from({ length: numLines - 1 }, (_, i) => {
        const lineIndex = i + 1; // Start from the second line
        const animatedLine = animatedLines.find(
          (line) => line.lineIndex === lineIndex
        );

        return (
          <svg
            key={lineIndex}
            className="absolute h-full"
            style={{ left: `${(lineIndex / (numLines - 1)) * 100}%` }}
            xmlns="http://www.w3.org/2000/svg"
            width="3"
            height="100%"
            viewBox="0 0 3 1000"
            preserveAspectRatio="none"
          >
            {/* Static Line */}
            <path
              d={`M1.5 0 V${visibleLineLength}`}
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="2"
            />

            {/* Animated Tracing Line */}
            {animatedLine && (
              <motion.path
                d={linePath}
                stroke="#58F0D4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeOpacity="0.2"
                fill="none"
                initial={{
                  strokeDasharray: `${tracingLineLength} ${lineLength}`,
                  strokeDashoffset:
                    animatedLine.direction === 'normal' ? 0 : -lineLength,
                }}
                animate={{
                  strokeDashoffset:
                    animatedLine.direction === 'normal' ? -lineLength : 0,
                }}
                transition={{
                  duration: animatedLine.animationDuration,
                  delay: animatedLine.animationDelay,
                  ease: 'linear',
                  repeat: Infinity,
                }}
              />
            )}
          </svg>
        );
      })}
    </div>
  );
};
