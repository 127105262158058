'use client';

import { useEffect, useState } from 'react';

declare global {
	interface Window {
		ChiliPiper?: {
			submit: (company: string, router: string, options: any) => void;
		};
	}
}

export function useChiliPiper() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		if (!window.ChiliPiper && !isLoaded) {
			const script = document.createElement('script');
			script.src = 'https://js.chilipiper.com/marketing.js';
			script.async = true;
			script.onload = () => {
				if (window.ChiliPiper && !isInitialized) {
					setIsInitialized(true);
					window.ChiliPiper.submit("cyware", "request-a-demo-router", {
						map: true,
						injectRootCss: true,
						lead: {}
					});
				}
				setIsLoaded(true);
			};
			script.onerror = (error) => {
				console.error('Error loading ChiliPiper script:', error);
			};
			document.body.appendChild(script);
		}
	}, [isLoaded, isInitialized]);

	const submitToChiliPiper = (leadData: any) => {
		if (window.ChiliPiper) {
			window.ChiliPiper.submit('cyware', 'request-a-demo', {
				map: true,
				lead: leadData,
				injectRootCss: true,
				onSuccess: function () {
					console.log('ChiliPiper router loaded successfully');
				},
				onError: function (error: any) {
					console.error('ChiliPiper router error:', error);
				}
			});
		} else {
			console.warn('ChiliPiper script failed to load');
		}
	};

	return {
		isLoaded,
		submitToChiliPiper,
		hasConsent: true // Always return true since we're always allowing ChiliPiper
	};
} 