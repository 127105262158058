const briefingOptions: { label: string; description: string; value: string }[] = [
  {
    label: 'Research & Analysis',
    description: 'Detailed reports on cyber threats, vulnerabilities, and trends.',
    value: 'research-and-analysis',
  },
  {
    label: 'Daily Threat Briefing',
    description: 'Quick, daily updates on the latest threats.',
    value: 'daily-threat-briefing',
  },
  {
    label: 'Weekly Threat Briefing',
    description: 'A comprehensive weekly summary of emerging threats.',
    value: 'weekly-threat-briefing',
  },
  {
    label: 'Monthly Threat Briefing',
    description: 'Monthly deep dives into critical cybersecurity issues.',
    value: 'monthly-threat-briefing',
  },
] as const;

export default function ThreatBriefingFormInputs({
  handleSubmit,
  onSubmit,
  onError,
  errors,
  register,
  isSubmitting,
  submittedMessage,
  serverError,
}: {
  handleSubmit: any;
  onSubmit: any;
  onError: any;
  errors: any;
  register: any;
  isSubmitting: any;
  submittedMessage: any;
  serverError: any;
}): JSX.Element {
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="Threat-Briefing-Form-non-Hubspot">
      <section className="mx-auto w-full max-w-lg px-8 md:px-4">
        {/* Business Email */}
        <div className="mb-4">
          <label className="text-sm mb-2 block font-bold text-gray-300" htmlFor="email">
            Business email*
          </label>
          <input
            aria-label="Enter Business Email"
            className={`mb-3 block w-full appearance-none rounded-md border bg-SLATE_BLUE px-4 py-3 leading-tight text-gray-300 focus:bg-gray-600 focus:outline-none ${
              errors.email ? 'border-red-500' : 'border-gray-600'
            }`}
            id="email"
            type="email"
            placeholder="email@company.com"
            {...register('email')}
          />
          {errors.email && <p className="mt-2 text-xs italic text-red-500">{errors.email.message}</p>}
        </div>

        {/* Briefing Subscriptions */}
        <div className="mb-6">
          <span className="text-sm mb-4 block font-bold text-gray-300" id="briefings-group">
            Select Briefings to Subscribe*
          </span>
          <div className="space-y-3" role="group" aria-labelledby="briefings-group">
            {briefingOptions.map(option => (
              <div
                key={option.value}
                className="group relative rounded-lg border border-gray-600 bg-SLATE_BLUE backdrop-blur-sm transition-all duration-300 hover:bg-SLATE_BLUE/60"
              >
                <label className="flex flex-col items-start p-4 min-w-0 flex-1 cursor-pointer">
                  <div className="text-sm flex items-center font-medium text-gray-300">
                    <input
                        type="checkbox"
                        className="rounded-none peer h-4 w-4 appearance-none border border-white/70 checked:border-GREEN-500 checked:bg-GREEN-500 focus:ring-0 focus:ring-offset-0"
                        value={option.value}
                        {...register('briefing_subscriptions')}
                      />
                    <span className="ml-3 transition-colors duration-200 peer-checked:text-GREEN-500">{option.label}</span>
                  </div>
                  <p className="ml-7 mt-1 text-xs text-gray-400">{option.description}</p>
                </label>
              </div>
            ))}
          </div>
          {errors.briefing_subscriptions && <p className="mt-2 text-xs italic text-red-500">{errors.briefing_subscriptions.message}</p>}
        </div>

        {/* Server Error */}
        {serverError && <p className="mt-2 text-xs italic text-red-500">{serverError}</p>}

        {/* Submit Button */}
        <div className="flex items-center justify-between pt-4">
          <button
            aria-label="Request Demo"
            className="focus:shadow-outline relative w-full rounded-md border-2 border-GREEN-500 bg-DEEPGREEN py-2.5 text-xs font-bold text-WHITE transition-colors duration-200 hover:bg-DEEPGREEN-500 disabled:opacity-50"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <SubmitLoader /> : submittedMessage ? submittedMessage : 'Submit'}
          </button>
        </div>
      </section>
    </form>
  );
}

function SubmitLoader(): JSX.Element {
  return (
    <div className="flex items-center justify-center gap-1 py-1">
      <span className="sr-only">Loading...</span>
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80 [animation-delay:-0.3s]" />
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80 [animation-delay:-0.15s]" />
      <div className="h-1.5 w-1.5 animate-bounce rounded-full bg-WHITE/80" />
    </div>
  );
}
