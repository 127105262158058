/**
 * Utility to load HubSpot tracking script temporarily, get the hubspotutk cookie, and then remove the script
 * This prevents double form submissions while still capturing the tracking cookie
 */

export async function getHubSpotCookie(): Promise<string | null> {
  // Check if cookie already exists (in case script was loaded previously)
  const existingCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('hubspotutk='));
  
  if (existingCookie) {
    return existingCookie.split('=')[1];
  }

  // If no cookie exists, load the script temporarily
  return new Promise((resolve) => {
    // Create script element
    const script = document.createElement('script');
    script.id = 'hs-script-loader-temp';
    script.src = '//js.hs-scripts.com/7288424.js';
    script.async = true;
    
    // Function to check for cookie and clean up
    const checkForCookie = () => {
      const cookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('hubspotutk='));
      
      if (cookie) {
        // Cookie found, clean up and resolve
        clearInterval(checkInterval);
        clearTimeout(timeout);
        
        // Remove the script
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        
        resolve(cookie.split('=')[1]);
      }
    };
    
    // Set up interval to check for cookie
    const checkInterval = setInterval(checkForCookie, 100);
    
    // Set timeout to stop checking after 3 seconds
    const timeout = setTimeout(() => {
      clearInterval(checkInterval);
      
      // Remove the script even if cookie wasn't found
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
      
      // Resolve with null if cookie wasn't found
      resolve(null);
    }, 3000);
    
    // Add script to document
    document.body.appendChild(script);
  });
} 