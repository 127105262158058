'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import ThreatBriefingFormInputs from '@/app/components/ui/Forms/ThreatBriefingSubsForm/ThreatBriefingFormInputs';

// Form Schema: Defines the structure and validation rules for the form data
const formSchema = z.object({
  email: z.string().email('Invalid email address'),
  briefing_subscriptions: z.array(z.string()).min(1, 'Please select at least one briefing subscription'),
});

// Form Data Type: Inferred from the schema for type safety
type FormData = z.infer<typeof formSchema>;

export default function ThreatBriefingSubscriptionForm({ handleCloseOnSubmit }: { handleCloseOnSubmit?: () => void }): JSX.Element {
  const [submittedMessage, setSubmittedMessage] = useState<string | null>(null);
  const [serverError, setServerError] = useState<string | null>(null);
  const [formContent, setFormContent] = useState<any>(null);

  // Initialize react-hook-form with zod resolver for validation
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  // Fetch form content from API on component mount
  useEffect(() => {
    fetch(`/api/getForm?formType=Threat Briefing Subscription`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(content => setFormContent(content))
      .catch(error => console.error('Error fetching form content:', error));
  }, []);

  // Form submission handler
  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      // Transform the data before sending to HubSpot
      const formattedFields = [
        { name: 'email', value: data.email },
        // Join the array of subscriptions into a semicolon-separated string
        { name: 'briefing_subscriptions', value: data.briefing_subscriptions.join(';') },
      ];

      // Send form data to HubSpot Forms API
      const response = await fetch(formContent?.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: formattedFields,
          context: {
            pageUri: 'https://www.cyware.com/threat-briefing-subscription',
            pageName: 'Threat Briefing Subscription',
            ipAddress: await fetch('https://api.ipify.org?format=json')
              .then(r => r.json())
              .then(data => data.ip),
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      // console.log('Form submitted successfully');
      setSubmittedMessage('Thank you for submitting the form!');

      // Reset form and clear submission message after a delay
      setTimeout(() => {
        reset();
        handleCloseOnSubmit && handleCloseOnSubmit();
        setSubmittedMessage(null);
      }, 1500);
    } catch (error) {
      console.error('Form submission error:', error);
      setServerError('An error occurred. Please try again.');
    }
  };

  // Error handler for form validation errors
  const onError: SubmitErrorHandler<FormData> = errors => {
    console.error('Form validation errors:', errors);
  };

  return (
    <ThreatBriefingFormInputs
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onError={onError}
      errors={errors}
      register={register}
      isSubmitting={isSubmitting}
      submittedMessage={submittedMessage}
      serverError={serverError}
    />
  );
}



// ? This form submits via HubSpot Forms API